<template>
  <div class='row'>

    <a-collapse :active-key='activeKey' class='col-12'>
      <a-collapse-panel key='1' :header='selectedPT ? "Yayıncı Antrenör: " + selectedPT.name + " " + selectedPT.surname : "Yayıncı Antrenörü Seçiniz"' :disabled='selectedPT'>

        <div class='table-responsive text-nowrap'>
          <a-table :loading='loading'
                   :columns='columns'
                   :data-source='ptItems'
                   :pagination='false'
                   :row-key='(record) => record.id'
                   :locale='{emptyText: "Antrenör Bulunamadı"}'
          >
            <template #id='{ record }'>
              <div>{{ record.id }}</div>
            </template>
            <template #avatar='{ record }'>
              <div class='vb__utils__avatar'>
                <a-image
                  :src='record.avatar.avatar4X'
                  alt='User'
                  fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                />
              </div>
            </template>
            <template #name='{ record }'>
              <div>{{ record.name }}</div>
            </template>
            <template #surname='{ record }'>
              <div>{{ record.surname }}</div>
            </template>
            <template #rate='{ record }'>
              <div>{{ roundHalf(record.rate) }}</div>
            </template>
            <template #operation='{record}'>
              <a-button class='btn btn-success' :class='selectedPT === record && "btn-danger"' @click='selectedPT = record; activeKey = 2'>
                {{ selectedPT === record ? 'Yayıncı Olarak Belirlendi' : 'Yayıncı Olarak Seç' }}
              </a-button>
            </template>
          </a-table>
        </div>
        <div class='clearfix mt-4'>
          <a-pagination
            class='float-right'
            @change='loadPersonalTrainer'
            v-model:page-size='pagination.recordsPerPage'
            :default-page-size='pagination.recordsPerPage'
            v-model:current='pagination.currentPage'
            :default-current='pagination.currentPage'
            :total='pagination.totalRecords'
          />
        </div>

      </a-collapse-panel>

      <a-collapse-panel key='2' header='Yayın Detayları' :disabled='!selectedPT'>
        <div class='row d-flex justify-content-center'>
          <div class='col-12' style='max-width: 600px;'>
            <image-resizer
              :loading='loading'
              :on-complete='beforeUpload'
              :max-size='988'
              required
              :error-text='"Fotoğraf Ölçüleri 800x500 Piksel ve Katlarında Olmalıdır!"'
            />

            <a-form
              label-align='left'
              layout='vertical'
            >
              <a-form-item label='Yayın Başlığı (*)' name='castTitle'>
                <a-input v-model:value='title' />
              </a-form-item>
              <a-form-item label='Yayın Başlangıç Zamanı (*)' name='castDate'>
                <a-locale-provider :locale='tr_TR'>
                  <a-date-picker
                    :locale='locale'
                    class='w-100'
                    v-model:value='startTime'
                    :disabled-date='checkIsDateEnabledForStartDateInput'
                    :show-time="{ format: 'HH:mm'}"
                    format='YYYY-MM-DD HH:mm:ss'
                    placeholder='Başlangıç Tarihi Ve Saati'
                  />
                </a-locale-provider>
                <a-tag color='warning' class='mt-2'>Yayınlar 1 Saat Sonra Otomatik Sonlandırılır !</a-tag>
              </a-form-item>
              <!--              <a-form-item label='Yayın Bitiş Zamanı' name='castDate'>-->
              <!--                <a-input-->
              <!--                  :value='endTime ? moment(endTime).format("YYYY-MM-DD HH:mm:ss") : ""'-->
              <!--                  disabled-->
              <!--                  placeholder='Bitiş Tarihi Ve Saati'-->
              <!--                />-->
              <!--              </a-form-item>-->
              <a-tag color='error' v-if='!isCompletedForm'>
                <template #icon>
                  <i class='fe fe-alert-circle' />
                </template>
                Yıldızlı (*) Alanlar Zorunludur.
              </a-tag>

              <div class='d-flex justify-content-between align-items-center mt-2'>
                <a-button @click='addNewBroadcast' :loading='loading' :disabled='!isCompletedForm' class='btn' :class='isCompletedForm ? "btn-success" : ""'>
                  Yeni Yayın Oluştur
                </a-button>

                <a-button @click='selectedPT = undefined; activeKey = 1' class='btn btn-primary btn-with-addon'>
              <span class='btn-addon'>
                <i class='btn-addon-icon fa fa-refresh'></i>
              </span>
                  Antrenörü Yeniden Seç
                </a-button>
              </div>
            </a-form>
          </div>
        </div>
      </a-collapse-panel>

    </a-collapse>

  </div>
</template>

<script>
import moment from 'moment'
import { BroadcastProgramAdministration, PersonalTrainerAdministration } from '../../services/api-service'
import { notification } from 'ant-design-vue'
import { roundHalf } from '@/utils/number'
import 'moment/locale/tr'
import locale from 'ant-design-vue/es/date-picker/locale/tr_TR'
import tr_TR from 'ant-design-vue/es/locale-provider/tr_TR'
import { useRouter } from 'vue-router'

moment.locale('tr')

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Avatar',
    dataIndex: 'avatar.avatar1X',
    slots: { customRender: 'avatar' },
  },
  {
    title: 'Adı',
    dataIndex: 'name',
  },
  {
    title: 'Soyadı',
    dataIndex: 'surname',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Derece',
    dataIndex: 'rate',
    slots: { customRender: 'rate' },
  },
  {
    title: 'Seçim',
    dataIndex: 'operation',
    className: 'w-100 text-right',
    slots: { customRender: 'operation' },
  },
]

export default {
  name: 'YeniYayinEkle',
  data() {
    const router = useRouter()

    tr_TR.DatePicker.lang.ok = 'Tamam'
    return {
      router,
      tr_TR,
      locale,
      moment,
      roundHalf,
      columns,
      dateFormat: 'YYYY/MM/DD',
      monthFormat: 'YYYY/MM',
      coverPhoto: '',
      title: '',
      startTime: '',
      endTime: '',
      selectedPT: undefined,
      ptItems: [],
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
      loading: false,
      activeKey: 1,
    }
  },
  computed: {
    isCompletedForm() {
      return (typeof this.selectedPT !== 'undefined' && this.coverPhoto !== '' && this.title !== '' && this.startTime !== '' && this.endTime !== '')
    },
  },
  watch: {
    startTime(newStartTime) {
      const mNewStartTime = moment(newStartTime)
      this.startTime = mNewStartTime.set({ second: 0, millisecond: 0 }).format()

      this.endTime = mNewStartTime.clone().add(60, 'minutes')
    },
    endTime(newEndTime) {
      const mNewEndTime = moment(newEndTime)

      if (this.startTime === '' || mNewEndTime.isSameOrBefore(this.startTime)) {
        this.startTime = mNewEndTime.clone().add(-60, 'minutes')
      }
    },
  },
  mounted() {
    this.loadPersonalTrainer()
  },
  methods: {
    beforeUpload(file) {
      this.coverPhoto = file
    },
    checkIsDateEnabledForStartDateInput(date) {
      const now = moment().add(-1, 'days').endOf('day')
      const oneYearLater = moment().add(1, 'years')

      return !date.isBetween(now, oneYearLater, 'seconds')
    },
    async loadPersonalTrainer(page) {
      this.loading = true

      try {
        const { data } = await PersonalTrainerAdministration.listPersonalTrainersForAdministration(page ?? undefined)
        if (data) {
          this.ptItems = data.personalTrainers
          this.pagination = data.pagination
        }
      } catch {
      }

      this.loading = false
    },
    async addNewBroadcast() {
      this.loading = true
      try {
        const { data } = await BroadcastProgramAdministration.createBroadcastProgram({
          personalTrainerId: this.selectedPT.metadataId,
          title: this.title,
          coverPhoto: this.coverPhoto,
          endsAt: this.endTime,
          startsAt: this.startTime,
        })

        if (data) {
          this.title = ''
          this.coverPhoto = ''
          this.endTime = ''
          this.startTime = ''
          this.selectedPT = undefined
        }

        await this.router.push({ path: '/yayin-takvimi' })

        notification.success({
          message: 'Yayın Başarıyla Eklendi!',
        })
      } catch {

      }
      this.loading = false
    },
  },
}
</script>
